* {
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body {
    font-size: 16px;
    margin: 0;
}

a {
    color: white;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

.flex {
    display: flex;
}

.main {
    padding: 0 24px 24px;
}

.toolbar {
    background-color: lightgray;
    padding: 16px 24px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-details {
        display: flex;
        align-items: center;

        .user-img {
            height: 32px;
            width: 32px;
            border-radius: 100%;
            border: 1px solid gray;
            margin-right: 4px;
        }
    }

    span {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
}
/*
Status indicators
*/
progress {
    width: 100%;
    height: 5px;
    position: relative;
    top: -1rem;
    // background-color: #f3f3f3;
    border: 0;
}

.activity-buttons {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button.activity-type {
        background-color: #4c9ae4;
        width: 80px;
        margin: 0 4px 4px 0;
        color: white;
        font-size: 16px;
    }
}

.icon-circle {
    border-radius: 100%;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    padding: 6px;
    margin: 0 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}
.icon-circle img {
    width: 20px;
    height: 20px;
}

td > div > select.custom-select,
td > input {
    height: 32px;
    font-size: 16px;
    border-radius: 4px;
    position: relative;
    vertical-align: middle;
}

button {
    border-radius: 4px;
    height: 32px;
    font-size: 16px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 4px;
    }

    &:hover {
        opacity: 0.8;
    }

    &.button-standard {
        background-color: #ced4da;
        color: #333;

        span {
            line-height: 20px;
        }
    }

    &.button-icon-combo {
        img {
            margin-right: 4px;
        }
    }

    &.button-important {
        font-weight: bold;
    }
}

.highlight-box {
    border-radius: 4px;
    background-color: #eeeeee;
    padding: 1rem;
    margin: 1rem 0;

    span {
        margin-bottom: 4px;

        &:first-child {
            font-weight: bold;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.day-container {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;

    h2 {
        display: flex;

        img {
            margin-left: 4px;
            cursor: pointer;
        }
    }
}

// Resets for Time Picker
ngx-mat-timepicker-content {
    button {
        background-color: transparent;
        color: var(--mat-mdc-fab-color, inherit);
    }
    input {
        background-color: transparent;
        color: inherit;
    }
    .mat-toolbar-row, .mat-toolbar-single-row {
        height:150px;
    }
}

// Dark Theme
body {
    background-color: #161d2e !important;
    color: #eee !important;
}
.highlight-box {
    background-color: #161d2e !important;
    color: white !important;
    border: 1px solid #717b94 !important;
}

.day-container {
    h2 {
        img {
            background: #717b94;
            border-radius: 50%;
            padding: 4px;
            height: 28px;
            width: 28px;
        }
    }
}

select.custom-select,
input {
    background-color: #555963;
    color: white;
    border: none;
}

.toolbar {
    background-color: #0a101f !important;
}

.icon-circle {
    background-color: #717b94 !important;
    color: black !important;
}

button {
    background-color: #a3a9bb;
    color: black;
    font-weight: bold;
}

button.activity-type {
    background-color: #406e8e !important;
}
